import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const SupportRegisterNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.div`
  div {
    ${pxToCssFont(30, 38)}
    margin: 0;
    margin-bottom: 24px;
  }
`;

export const ModalDescription = styled.div`
  div {
    ${pxToCssFont(18, 24)}
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin: 16px auto 24px;

  > div {
    padding: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 406px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto 16px;

  @media (min-width: ${breakpoints.desktop}) {
    width: 406px;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.$bebebe};
  margin: 24px 0;
`;

export const ModalDisclaimer = styled.div`
  margin: 0;

  div {
    ${pxToCssFont(18, 24)}
    margin: 0;
  }
`;

export const ModalSupport = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;

  @media (min-width: ${breakpoints.desktop}) {
    span {
      font-size: 24px;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 32px !important;
    height: 32px !important;
    max-width: 32px;
  }
`;

export const FreeNumerContainer = styled.div`
  margin-top: 24px;
  border-radius: 32px;
  padding: 10.5px 15px;
  cursor: pointer;
  width: 100%;
  background: #fff;
  border: 1px solid #262626;
  text-align: center;

  a {
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.55;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #262626;
    text-decoration: none;
  }
`;

export const ModalAuthorization = styled.div`
  div {
    ${pxToCssFont(14, 18)}
    margin: 24px 0 0;
  }
`;

