import { ButtonSlideCustom, CustomText, ImageAtoms, Input } from '@vfit/shared/atoms';
import { fonts } from '@vfit/shared/themes';
import { useDeviceType } from '@vfit/shared/hooks';
import { IActionStyle } from '@vfit/shared/models';
import { ChangeEvent, useState } from 'react';

import { checkIsApp } from '@vfit/consumer/data-access';
import { ISupportRegisterNumber, IToken } from './supportRegisterNumber.models';
import {
  ButtonWrapper,
  Divider,
  FreeNumerContainer,
  IconWrapper,
  InputWrapper,
  ModalAuthorization,
  ModalDescription,
  ModalDisclaimer,
  ModalSupport,
  ModalTitle,
  SupportRegisterNumberContainer,
} from './supportRegisterNumber.style';
import { dxlCloudClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';

const SupportRegisterNumber = (props: ISupportRegisterNumber) => {
  const {
    title,
    description,
    placeholder,
    buttonTitle,
    freeNumberLabel,
    freeNumberUrl,
    freeNumberLabelDisclaimer,
    freeNumberLabelDisclaimerMobile,
    footerDescription,
    footerNumberUrl,
    footerIcon,
    footerNumberLabel,
    footerDescriptionMobile,
    disclaimerHtml,
    offerId = null,
    offerName = null,
    isStickyWA = false,
    goToNextStep,
  } = props;

  const { isMobile } = useDeviceType();
  const isApp = checkIsApp();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [msisdn, setMsisdn] = useState('');

  const handleClick = async () => {
    // Send offer details to DXL for tracking after the user instering the MSISDN for support
    await dxlCloudClient.get(API.GET_DXL_LEAD_WHATSAPP, {
      searchParams: {
        ch: 'WA_WEB',
        desc: offerName,
        cvmOfferId: offerId,
        msisdn: msisdn,
      },
    });
    if (!isStickyWA) {
      // Send MSISDN to DXL for generating token to send it to VOLA
      const token = (await dxlCloudClient.get(API.GET_DXL_TOKENIZE, {
        searchParams: { msisdn: msisdn },
      })) as IToken;

      sessionStorage.setItem('UTV', token.d);
    }

    goToNextStep?.();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setIsValid(true);
      setMsisdn(event.target.value);
    } else {
      setIsValid(false);
    }
  };

  return (
    <SupportRegisterNumberContainer>
      <ModalTitle>
        <CustomText
          fontFamily={fonts.exbold}
          text={title || ''}
          modal
          textAlign={isMobile || isApp ? 'start' : 'center'}
        />
      </ModalTitle>
      <ModalDescription>
        <CustomText
          fontFamily={fonts.regular}
          text={description || ''}
          modal
          textAlign={isMobile || isApp ? 'start' : 'center'}
        />
      </ModalDescription>
      <InputWrapper>
        <Input
          type="text"
          inputMode="numeric"
          // maxLength={1}
          onChange={(e) => handleChange(e)}
          // value={otpValues[i]}
          // ref={ref}
          label={placeholder}
          data-name="input_supportRegisterNumber"
        />
      </InputWrapper>
      <ButtonWrapper>
        <ButtonSlideCustom
          label={buttonTitle}
          onClick={() => handleClick()}
          name="action_supportRegisterNumber"
          style={IActionStyle.PRIMARY}
          disabled={!isValid}
        />
      </ButtonWrapper>
      {!isMobile && (
        <ModalDisclaimer>
          <CustomText fontFamily={fonts.regular} text={freeNumberLabelDisclaimer || ''} modal />
        </ModalDisclaimer>
      )}
      {isMobile && (
        <>
          <ModalDisclaimer>
            <CustomText
              fontFamily={fonts.regular}
              text={freeNumberLabelDisclaimerMobile || ''}
              modal
            />
          </ModalDisclaimer>
          <FreeNumerContainer>
            <a href={`tel:${freeNumberUrl}`}>{freeNumberLabel}</a>
          </FreeNumerContainer>
        </>
      )}
      <Divider />
      <ModalSupport>
        <IconWrapper>
          <ImageAtoms nameCard="supportIcon" image={footerIcon} alt="icona operatore" />
        </IconWrapper>
        <CustomText
          margin={[0, 0, 0, 8]}
          fontFamily={fonts.regular}
          size={18}
          lineHeight={20}
          lineHeightMobile={24}
          text={(isMobile || isApp ? footerDescriptionMobile : footerDescription) || ''}
          textAlign="left"
          modal
        />
      </ModalSupport>
      {isMobile ||
        (isApp && (
          <FreeNumerContainer>
            <a href={`tel:${footerNumberUrl}`}>{footerNumberLabel}</a>
          </FreeNumerContainer>
        ))}
      <ModalAuthorization>
        <CustomText fontFamily={fonts.regular} textAlign="left" text={disclaimerHtml || ''} modal />
      </ModalAuthorization>
    </SupportRegisterNumberContainer>
  );
};

export default SupportRegisterNumber;

